import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { REPORT_NAMESPACE, REPORT_BASE_PATH } from './constants';
import ReportRoute from './pages/ReportRoute';

export const powerBiCommonModuleConfig = (
  permissions: string[],
  intl: IntlShape,
  title: string,
  reportName: string,
): ModuleHierachy => {
  return {
    title,
    path: `${REPORT_BASE_PATH}/${reportName}`,
    namespace: REPORT_NAMESPACE,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    component: ReportRoute,
    page: [
      {
        title,
        path: `${REPORT_BASE_PATH}/${reportName}`,
      },
    ],
  };
};
