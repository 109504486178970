import React, { FC, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { authenticate, setAgentInfo, setTokenInfo } from '../network/authSlice';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { decodeJWT, fetchCurrentAgentInfo, getToken } from '../network/authCrud';
import { LayoutSplashScreen } from 'src/app/layout';
import { appendAlertItem, AlertType } from '../../../../redux/common/commonSlice';
import { ParamsProps } from '../../../common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import { redirectMap, RedirectType } from '../../../common/types/common-types';
import { fetchAppConfigs } from '../../../common/network/crud/configsCurd';
import { takeLoginEvent } from '../../../common/ga/ga';
import { getUserLBU } from '../../../common/ga/utils';
import { useIntl } from 'react-intl';
import { preFillZero } from '../../../common/utils/common-utils';
import { useGAScreenViewEvent } from '../../../common/ga/hook/useGAScreenViewEvent';
import { updateUserProperties } from 'src/app/common/ga/ga-helper';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { useLang } from '../../../../app/i18n';

type CallbackProps = {} & ParamsProps;

const Callback: FC<CallbackProps> = ({ initialParams }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const authorizationCode = initialParams ? initialParams.authorizationCode : '';
  const { redirect } = useParams<{ redirect: string }>();
  const lang = useLang();

  useGAScreenViewEvent(
    {
      module: 'Auth',
      feature: 'Login',
      journey: 'agent_login',
      stage: 'sso_login',
      screen_name: 'CallbackPage',
    },
    true,
  );

  const redirectTo = useMemo(() => {
    return redirect && redirectMap[redirect as RedirectType] ? redirectMap[redirect as RedirectType] : '/';
  }, [redirect]);

  useEffect(() => {
    const getAgencyInfo = async (token: string) => {
      const headers = { Authorization: `Bearer ${token}` };
      const currentAgentInfo = await fetchCurrentAgentInfo(lang, dispatch, { headers });
      dispatch(setAgentInfo(currentAgentInfo));
      globalStore.setAgent(currentAgentInfo);
    };

    const login = async () => {
      if (authorizationCode) {
        try {
          const response = await getToken(authorizationCode, dispatch);
          const { accessToken, expiresIn, refreshToken } = response;
          const appConfigs = await fetchAppConfigs(accessToken, dispatch);
          await getAgencyInfo(accessToken);
          if (accessToken && expiresIn && refreshToken && appConfigs) {
            //TODO: login success
            window.localStorage.setItem('jwt', accessToken);
            const tokenInfo = await decodeJWT(accessToken);
            dispatch(authenticate(accessToken));
            dispatch(setTokenInfo(tokenInfo));
            const expireDate = moment(new Date()).add(Number(expiresIn), 's').toDate();
            const abondonSession = moment(new Date()).add(1, 'd').toDate();
            window.localStorage.setItem('expireDate', expireDate.toISOString());
            window.localStorage.setItem('abondonSession', abondonSession.toISOString());
            window.localStorage.setItem('refreshToken', refreshToken);
            window.localStorage.setItem('appConfigs', JSON.stringify(appConfigs));

            updateUserProperties({
              user_id: preFillZero(tokenInfo.sub),
              user_lbu: getUserLBU(appConfigs),
              region: tokenInfo.region,
              language: intl.locale,
              user_role: tokenInfo.role,
              channel: tokenInfo.channel,
            });

            takeLoginEvent(preFillZero(tokenInfo.sub), {
              module: 'Auth',
              feature: 'Login',
              journey: 'agent_login',
              stage: 'login_success',
              screen_name: 'CallbackPage',
              method: 'sso',
              status: 'Success',
            });

            if (redirectTo) {
              history.push(redirectTo);
            }
            return;
          }
        } catch (err) {}
      }
      history.push(redirectTo);
    };

    switch (redirect) {
      case RedirectType.APPLICATION_DOWNLOAD:
        if (!authorizationCode) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Error',
                content: 'Authentication failure',
              },
            ]),
          );
          history.push('/');
          return;
        }
        login();
        return;
      default:
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
        return;
    }
    // eslint-disable-next-line
  }, []);

  return <>{redirect === RedirectType.APPLICATION_DOWNLOAD && <LayoutSplashScreen />}</>;
};

export default Callback;
