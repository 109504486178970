import React, { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { REPORT_BASE_PATH, ReportConfig } from '../constants';
import PowerBiCommonComponent from '../components/PowerBiCommonComponent';
import { configManager } from 'src/app/common/utils';
import { get } from 'lodash';

const ReportRoute: FC<RouteComponentProps> = (props) => {
  const reportName = props.location.pathname.split('/').pop();

  const powerBiReportsConfig: ReportConfig[] = get(
    configManager.getConfig('sales-common'),
    'Sales.powerbi.reports',
    [],
  );

  const reportNames: string[] = powerBiReportsConfig.map((report) => report.reportName);

  const isValidReportName = (reportName: string): boolean => {
    return reportNames.includes(reportName);
  };

  if (!reportName || !isValidReportName(reportName)) {
    return <Redirect to="/error" />;
  }

  return (
    <Switch>
      <PruRoute
        exact
        path={`${REPORT_BASE_PATH}/${reportName}`}
        render={() => <PowerBiCommonComponent reportName={reportName} />}
      />
      <Redirect to="/error" />
    </Switch>
  );
};

export default ReportRoute;
