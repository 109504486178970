import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText,
  DialogTitle,
  Icon
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
//@ts-ignore
import * as Yup from 'yup';
import { AlertType, appendAlertItem } from '../../../../redux/common/commonSlice';
import { useLang } from '../../../i18n';
import { preFillZero } from '../../../common/utils/common-utils';
import { agentLoginOtp, fetchCurrentAgentInfo, getOTP, verifyOTP, fetchRsaKeys, resendOTP, decodeJWT } from '../network/authCrud';
import { authenticate, setAgentInfo, setTokenInfo, incrementLoginAttempt, resetLoginAttempt } from '../network/authSlice';
import { useStyles } from './Login.style';
// import SgPasswordEncryptor from '../utils/lib/sg.password-encryptor';
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics';
import { fetchAppConfigs } from '../../../common/network/crud/configsCurd';
import { takeLoginEvent } from '../../../common/ga/ga';
import { getUserLBU } from '../../../common/ga/utils';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { updateUserProperties } from 'src/app/common/ga/ga-helper';
import DialogOtp from './DialogOtp';
import { EMAIL_OTP_ALLOWED_DOMAINS } from 'src/app/common/constants';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

const initialValues = {
  agentCode: '',
  password: '',
};

const REACT_APP_LOGIN_WITH_OTP: boolean = (window.envConfig['REACT_APP_LOGIN_WITH_OTP'] as string) == 'true';
const REACT_APP_ENABLE_VERIFICATION_TRAILS: boolean =
  (window.envConfig['REACT_APP_ENABLE_VERIFICATION_TRAILS'] as string) == 'true';

const OTP_MAX_COUNT = 10; // 10 secs

export interface AgentAuthInfo {
  email: string | null;
  mobileNumber: string | null;
}

export enum OtpVerificationMethodEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

const Login: FC<RouteComponentProps> = ({ history, location, match }) => {
  const intl = useIntl();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [displayOtp, setDisplayOtp] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [loginRes, setLoginRes] = useState<any>();
  const [agentCode, setAgentCode] = useState<string>();
  const [agentNamePhone, setAgentNamePhone] = useState<{
    name?: string;
    phone?: string;
  }>({});
  const intervalRef = useRef<any>(null);
  const { classes } = useStyles();

  const [count, changeCount] = useState<number>(OTP_MAX_COUNT);
  const [otpError, setOTPError] = useState<boolean>(false);
  const [randomKey, setRandomKey] = useState<string>();
  const [publicKey, setPublicKey] = useState<string>();
  const [transactionId, setTransactionId] = useState<string>();
  const [customerId, setCustomerId] = useState<string>();
  const [agentAuthInfo, setAgentAuthInfo] = useState<AgentAuthInfo>({email: null, mobileNumber: null});
  const [otpVerificationMethod, setOtpVerificationMethod] = useState<OtpVerificationMethodEnum>(OtpVerificationMethodEnum.EMAIL);
  const [emailValidationError, setEmailValidationError] = useState<boolean>(false);

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [secs, setSecs] = useState(60);
  const loginAttempt: number = useSelector((state: any) => {
    return state?.auth?.loginAttempt;
  });

  const updateLastFailedLoginTimestamp = (countdown: number) => {
    const finalTimeStamp = (moment().unix() + countdown).toString();
    console.log('save to storage', finalTimeStamp);
    window.localStorage.setItem('USER-LAST-FAILED-LOGIN-TIMESTAMP', finalTimeStamp);
  };

  useEffect(() => {
    const lastFailedLoginTimestamp = Number(window.localStorage.getItem('USER-LAST-FAILED-LOGIN-TIMESTAMP'));

    if (lastFailedLoginTimestamp) {
      const diffSecs = moment.unix(lastFailedLoginTimestamp).diff(moment(), 'seconds');
      setSecs(diffSecs < 0 ? 0 : diffSecs);
    }
  }, []);

  useEffect(() => {
    console.log('check login attempt', loginAttempt);
    if (loginAttempt >= 3) {
      setIsDialogVisible(true);
      const timerId = setInterval(() => {
        if (secs <= 0) {
          setIsDialogVisible(false);
          dispatch(resetLoginAttempt()); /* PACS Customization - PACSPFAP-796 */
          clearInterval(timerId);
        } else {
          setSecs((s) => s - 1);
        }
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [secs, loginAttempt]);

  useEffect(() => {
    if (loginAttempt >= 3) {
      const rand = Math.floor(60 - Math.random() * 45);
      setSecs(rand);
      updateLastFailedLoginTimestamp(rand);
    }
  }, [loginAttempt]);

  const onOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setName(event.target.value);
    const val = event.target.value;
    if (!val) {
      return setOtpCode('');
    }
    // const regex = /^\d+$/; // number only
    const regex = new RegExp(/^\d+$/); // number only
    if (!val.match(regex)) {
      return;
    }
    //const trimedVal = Math.max(0, parseInt(val)).toString().slice(0, 6); // 6 chars
    const trimedVal = val.toString().slice(0, 6); // 6 chars
    setOtpCode(trimedVal);
  };

  // clear otp timer on unmount
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (count === OTP_MAX_COUNT) {
      intervalRef.current = setInterval(() => {
        changeCount((preCount) => preCount - 1);
      }, 1000);
    } else if (count === 0) {
      clearInterval(intervalRef.current);
    }
  }, [count]);

  const onResendOtp = useCallback(() => {
    changeCount(OTP_MAX_COUNT);
    sendOTP(agentCode, agentNamePhone.phone);
  }, [agentCode, agentNamePhone]);

  const onRetryOtp = useCallback(() => {
    changeCount(OTP_MAX_COUNT);
    resendOtp(agentCode, otpVerificationMethod);
  }, [agentCode, otpVerificationMethod]);
  const dispatch = useDispatch();
  const lang = useLang();

  const LoginSchema = Yup.object().shape({
    agentCode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const getInputClasses = (fieldname: string) => {
    //@ts-ignore
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    //@ts-ignore
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };
  // const onResendOtp = () => {};
  const onSubmitOtp = () => {
    // if (!otpCode || otpCode.length !== 6) {
    //   return setOTPError(true);
    // }
    setOTPError(false);
    const optCodeData = {
      optCode: otpCode,
      agentCode: agentCode,
      lang: lang,
      enableVerificationTrials: REACT_APP_ENABLE_VERIFICATION_TRAILS,
    };

    const headers = { Authorization: `Bearer ${loginRes.accessToken}` };
    if (otpCode) {
      const forgeRockId = window.localStorage.getItem('forgeRockId') || '';
      verifyOTP(
        loginRes.transactionId,
        loginRes.username,
        loginRes.customerId,
        loginRes.forgeRockId,
        otpCode.toString(),
        dispatch,
      )
        .then(async (res) => {
          if (res?.accessToken) {
            /*const res: any = loginRes;*/
            const appConfigs = await fetchAppConfigs(res.accessToken, dispatch);
            if (!appConfigs) throw Error();
            const agentInfo = await getAgencyInfo(res.accessToken);
            if (!agentInfo) throw Error('No agent info.');

            const tokenInfo = await decodeJWT(res.accessToken);
            dispatch(authenticate(res.accessToken));
            dispatch(setTokenInfo(tokenInfo));
            const expireDate = moment(new Date()).add(res.expiresIn, 's').toDate();
            const abondonSession = moment(new Date()).add(1, 'd').toDate();
            window.localStorage.removeItem('transactionId');
            window.localStorage.removeItem('customerId');
            window.localStorage.removeItem('forgeRockId');
            window.localStorage.setItem('expireDate', expireDate.toISOString());
            window.localStorage.setItem('abondonSession', abondonSession.toISOString());
            window.localStorage.setItem('refreshToken', res.refreshToken);
            window.localStorage.setItem('appConfigs', JSON.stringify(appConfigs));
            window.localStorage.setItem('jwt', res.accessToken);

            const redirectLink = window.localStorage.getItem('redirect');
            if (redirectLink) {
              window.localStorage.removeItem('redirect');
              history.push(redirectLink);
            } else {
              history.push('/');
            }

            updateUserProperties({
              user_id: preFillZero(tokenInfo.sub),
              user_lbu: getUserLBU(appConfigs),
              region: tokenInfo.region,
              language: intl.locale,
              user_role: tokenInfo.role,
              channel: tokenInfo.channel,
            });

            takeLoginEvent(preFillZero(tokenInfo.sub), {
              module: 'Auth',
              feature: 'Login',
              journey: 'agent_login',
              stage: 'login_success',
              screen_name: 'LoginPage',
              method: 'password',
              status: 'Success',
            });
          } else {
            if (res?.extraData?.forgeRockId) {
              window.localStorage.setItem('forgeRockId', res.extraData.forgeRockId);
              setLoginRes({ ...loginRes, forgeRockId: res.extraData.forgeRockId });
            }
            /*console.log('OTP incorrect');*/
            setOTPError(true);
          }
        })
        .catch((err) => {
          if (err?.extraData?.forgeRockId) {
            window.localStorage.setItem('forgeRockId', err.extraData.forgeRockId);
          }
          takeLoginEvent(preFillZero(agentCode || ''), {
            module: 'Auth',
            feature: 'Login',
            journey: 'agent_login',
            stage: 'login_fail',
            screen_name: 'LoginPage',
            method: 'password',
            status: 'Fail',
          });
          setOTPError(true);
        });
    } else {
      setOTPError(true);
      window.localStorage.removeItem('jwt');
      setLoginRes(null);
    }
  };
  const sendOTP = (agentCode: string | undefined, mobile: string | undefined, token?: string) => {
    const getOtpData = {
      agentCode: agentCode,
      lang: lang?.includes('zh') ? 'zh' : lang, // [en,zh-Hant] api only support [en,zh]
      enableVerificationTrials: REACT_APP_ENABLE_VERIFICATION_TRAILS || false,
      phoneNum: mobile,
    };
    if (!agentCode || !mobile) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'SEND OTP FAIL',
            content: `Fail to send OTP, please try again later`,
          },
        ]),
      );
    }
    const headers = { Authorization: `Bearer ${token || loginRes.accessToken}` };
    getOTP(getOtpData, dispatch, { headers })
      .then((res) => {
        setDisplayOtp(true);
        changeCount(OTP_MAX_COUNT);
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'SEND OTP FAIL',
              content: `Fail to send OTP, please try again later`,
            },
          ]),
        );
        window.localStorage.removeItem('jwt');
        setLoginRes(null);
      });
  };

  const resendOtp = (username: string | undefined, _otpVerificationMethod: OtpVerificationMethodEnum) => {
    const transactionId = window.localStorage.getItem('transactionId');
    const customerId = window.localStorage.getItem('customerId');
    const forgeRockId = window.localStorage.getItem('forgeRockId');

    if (!username || !transactionId || !customerId) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'SEND OTP FAIL',
            content: `Fail to send OTP, please try again later`,
          },
        ]),
      );
    }
    /*console.log('transactionId:' + transactionId);*/
    resendOTP(transactionId, username, customerId, forgeRockId, _otpVerificationMethod, dispatch)
      .then((res: any) => {
        setDisplayOtp(true);
        changeCount(OTP_MAX_COUNT);
        window.localStorage.setItem('forgeRockId', res.forgeRockId);
        setLoginRes(res) // update loginRes with new forgeRockId
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'SEND OTP FAIL',
              content: `Fail to send OTP, please try again later`,
            },
          ]),
        );
        window.localStorage.removeItem('jwt');
        setLoginRes(null);
      });
  };
  const getOtpCode = async (agentCode: string, token: string) => {
    const headers = { Authorization: `Bearer ${token}` };
    const currentAgentInfo = await fetchCurrentAgentInfo(lang, dispatch, { headers });
    const mobile = currentAgentInfo?.phone?.mobile;
    const agentName = currentAgentInfo.agencyName;
    setAgentNamePhone({ name: agentName, phone: mobile });
    if (mobile) {
      sendOTP(agentCode, mobile, token);
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'No Phone Record',
            content: `Cannot find the phone belongs to the agent`,
          },
        ]),
      );
      window.localStorage.removeItem('jwt');
      setLoginRes(null);
    }
  };

  const getAgencyInfo = async (token: string) => {
    const headers = { Authorization: `Bearer ${token}` };
    const currentAgentInfo = await fetchCurrentAgentInfo(lang, dispatch, { headers });
    dispatch(setAgentInfo(currentAgentInfo));
    globalStore.setAgent(currentAgentInfo);
    return currentAgentInfo;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);

      const analytics = getAnalytics();
      setUserId(analytics, preFillZero(values.agentCode));
      setUserProperties(analytics, { Agent: preFillZero(values.agentCode) });

      if (values.password) {
        fetchRsaKeys(dispatch)
          .then((keys) => {
            //console.log('keys:' + keys);
            if (keys.payload.transactionId) {
              const { transactionId, publicKey } = keys.payload;
              setPublicKey(publicKey);
              var myObjectInstance = new PACSCryptography(publicKey);
              var encryptedPassword = myObjectInstance.encryptPassword(values.password);
              return { encrypted: encryptedPassword, transactionId: transactionId };
            }
          })
          .then((newres) =>
            agentLoginOtp(
              preFillZero(values.agentCode),
              newres?.encrypted as unknown as string,
              newres?.transactionId as unknown as string,
              otpVerificationMethod,
              dispatch,
            ),
          )
          .then(async (res) => {
            if (!res.isStaffLoginAccount && !validateEmailDomain(res.email)) {
              return setEmailValidationError(true);
            }
            setLoginRes(res);
            setSubmitting(false);
            setLoading(false);
            setAgentCode(values.agentCode);
            window.localStorage.setItem('transactionId', res.transactionId);
            window.localStorage.setItem('customerId', res.customerId);
            window.localStorage.setItem('forgeRockId', res.forgeRockId);
            setDisplayOtp(true);
            setAgentAuthInfo({ email: res.email || null, mobileNumber: res.mobileNumber || null });
          })
          .catch(() => {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Login Failed',
                  content: `Key in a valid agent code/password`,
                },
              ]),
            );
            dispatch(incrementLoginAttempt()); /* PACS Customization - PACSPFAP-796 */
            window.localStorage.removeItem('jwt');
            setLoginRes(null);
            setLoading(false);
            setSubmitting(false);
          });
      }
    },
  });

  const handleOnCloseDialog = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: string) => {
    if (reason !== 'backdropClick') {
      setDisplayOtp(false);
      window.localStorage.removeItem('jwt');
    }
  }

  const validateEmailDomain = (email: string | null): boolean => {
    if(!email) return false;
    const emailSchema = Yup.string().email();
    const isValidEmail = emailSchema.isValidSync(email);
    let allowedDomainsList = EMAIL_OTP_ALLOWED_DOMAINS
    if (window.envConfig['REACT_APP_ENV'] === "dev") {
      allowedDomainsList = [...EMAIL_OTP_ALLOWED_DOMAINS , "@prudential.com.sg"];
    }
    const isAllowedDomain = allowedDomainsList.some(domain => email.endsWith(domain));
    return isValidEmail && isAllowedDomain;
  };

  /* //Remove countdown text
   * const resendText = count > 0 ? `Resend (${count}s)` : 'Resend'; */
  const resendText = count > 0 ? `Resend OTP` : 'Resend OTP';
  const btnDisabled = !otpCode || otpCode.length !== 6;

  return (
    <div className={classes.loginWrapper}>
      <div className={classes.loginHeader}>
        <h3 className={classes.loginTitle}>
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className={classes.loginSubtitle}>Enter your agent code and password</p>
      </div>

      <form onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <div className={classes.tips} style={{ backgroundColor: '#ffe2e5' }}>
            {formik.status}
          </div>
        ) : (
          <div className={classes.tips}>
            <div>
              Use your <strong>Agent Code</strong> and <strong>Password</strong> to continue.
            </div>
          </div>
        )}

        <div className={classes.formItem}>
          <input
            placeholder="Agent Code"
            type="text"
            className={classes.input}
            {...formik.getFieldProps('agentCode')}
          />
          {formik.touched.agentCode ? (
            <Icon
              className={`${formik.errors.agentCode ? 'fa fa-exclamation-circle' : 'fa fa-check'} ${classes.checkIcon}`}
              style={{ color: formik.errors.agentCode ? '#F64E60' : '#1BC5BD' }}
            />
          ) : null}
          {formik.touched.agentCode && formik.errors.agentCode ? (
            <div className={classes.messageContainer}>{formik.errors.agentCode}</div>
          ) : null}
        </div>
        <div className={classes.formItem}>
          <input
            placeholder="Password"
            type="password"
            className={classes.input}
            //@ts-ignore
            name="password"
            autoComplete="on"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password ? (
            <Icon
              className={`${formik.errors.password ? 'fa fa-exclamation-circle' : 'fa fa-check'} ${classes.checkIcon}`}
              style={{ color: formik.errors.password ? '#F64E60' : '#1BC5BD' }}
            />
          ) : null}
          {formik.touched.password && formik.errors.password ? (
            <div className={classes.messageContainer}>{formik.errors.password}</div>
          ) : null}
        </div>

        <div className={classes.btnWrapper}>
          <button type="submit" disabled={formik.isSubmitting} className={classes.btn}>
            <span>Sign In</span>
            {loading && <span className={classes.loading}></span>}
          </button>
        </div>
      </form>
      <div className={classes.separator}></div>
        <DialogOtp
          displayOtp={displayOtp}
          otpError={otpError}
          otpCode={otpCode}
          onOtpChange={onOtpChange}
          onRetryOtp={onRetryOtp}
          count={count}
          resendText={resendText}
          btnDisabled={btnDisabled}
          onSubmitOtp={onSubmitOtp}
          handleOnCloseDialog={handleOnCloseDialog}
          agentAuthInfo={agentAuthInfo}
          formik={formik}
          otpVerificationMethod={otpVerificationMethod}
          setOtpVerificationMethod={setOtpVerificationMethod}
          isStaffLoginAccount={loginRes?.isStaffLoginAccount}
        />

      {/* PACS Customization - PACSPFAP-796 */}
      <Dialog
        open={isDialogVisible}
        onClose={(event, reason) => {
          //setDisplayOtp(false);
          if (reason !== 'backdropClick') {
            dispatch(resetLoginAttempt());
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
      >
        <div style={{ textAlign: 'center' }}>
          <DialogTitle>{`Maximum login attempt reached`}</DialogTitle>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div style={{ textAlign: 'center' }}>
            <DialogContentText>{`You are attempting to login too many times. Please try again in`}</DialogContentText>
            <DialogContentText>{`${secs > 9 ? '' : '0'}${secs}s`}</DialogContentText>
          </div>
        </DialogContent>
      </Dialog>
      {/* PACS Customization - PACSPFAP-796 */}

      <Dialog
        open={emailValidationError}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            return
          }
          setEmailValidationError(false);
          formik.setSubmitting(false)
          setLoading(false);
          localStorage.clear()
        }}
        maxWidth="sm"
        disableEscapeKeyDown
      >
         <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <strong>{`Error`}</strong>
      </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>{t("pruleads.login.otp.invalid_email_error")}</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => {
            setEmailValidationError(false);
            formik.setSubmitting(false)
            setLoading(false);
            localStorage.clear()
          }}
        >
          {`OK`}
        </Button>
      </DialogActions>
      </Dialog>
    </div>
  );
};

export default Login;
