import React, { useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { makeStyles } from 'tss-react/mui';
import { getReportList, getReportToken } from '../network/reportsCrud';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
const powerbi = require('powerbi-client');

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '80vh',
  },
}));

interface PowerBiCommonComponentProps {
  reportName: string;
}

const PowerBiCommonComponent: React.FC<PowerBiCommonComponentProps> = ({ reportName }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [powerBIEmbedData, setPowerBIEmbedData] = useState({
    reportId: '',
    embedUrl: '',
    embedToken: '',
    tokenExpiration: '',
  });
  const [selectedReport, setSelectedReport] = useState({ id: '', embedUrl: '', datasetId: '' });

  const { data: reportListResponse } = useRequest(() => getReportList(dispatch));
  const { runAsync } = useRequest(async (reportId, datasetId) => getReportToken(reportId, datasetId, dispatch), {
    manual: true,
  });
  const MINUTES_BEFORE_EXPIRATION = 10;
  const INTERVAL_TIME = 15 * 60 * 1000;

  useEffect(() => {
    // Implementing the setInterval method
    const interval = setInterval(() => checkTokenAndUpdate(), INTERVAL_TIME);

    // Clearing the interval
    return () => clearInterval(interval);
  }, [INTERVAL_TIME]);

  useEffect(() => {
    handleSelectReport();
  }, [reportName, reportListResponse]);

  const checkTokenAndUpdate = async () => {
    // Get the current time
    const currentTime = Date.now();
    const expiration = Date.parse(powerBIEmbedData.tokenExpiration);

    // Time until token expiration in milliseconds
    const timeUntilExpiration = expiration - currentTime;
    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    // Update the token if it is about to expire
    if (timeUntilExpiration <= timeToUpdate) {
      // Updating report access token

      const newAccessToken = await runAsync(selectedReport.id, selectedReport.datasetId);

      // Update the new token expiration time
      setPowerBIEmbedData({
        reportId: selectedReport.id,
        embedUrl: selectedReport.embedUrl,
        embedToken: newAccessToken.token,
        tokenExpiration: newAccessToken.expiration,
      });

      // Get a reference to the embedded report HTML element
      const embedContainer = document.getElementById('embedContainer');

      // Get a reference to the embedded report.
      let report = powerbi.get(embedContainer);

      // Set the new access token
      report.setAccessToken(newAccessToken.token);
    }
  };

  const handleSelectReport = async () => {
    const reportData = reportListResponse?.find((report) => report.name === reportName);
    if (reportData) {
      const reportToken = await runAsync(reportData.id, reportData.datasetId);
      setPowerBIEmbedData({
        reportId: reportData.id,
        embedUrl: reportData.embedUrl,
        embedToken: reportToken.token,
        tokenExpiration: reportToken.expiration,
      });
      setSelectedReport(reportData);
    }
  };

  return (
    <div id="embedContainer">
      {powerBIEmbedData.embedToken && (
        <PowerBIEmbed
          key={powerBIEmbedData.embedToken}
          embedConfig={{
            type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: powerBIEmbedData.reportId,
            embedUrl: powerBIEmbedData.embedUrl,
            accessToken: powerBIEmbedData.embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: true,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                'loaded',
                function () {
                  console.log('Report loaded');
                },
              ],
              [
                'rendered',
                function (data) {
                  console.log('Report rendered');
                },
              ],
              [
                'error',
                function (event) {
                  console.log(event?.detail);
                },
              ],
              ['visualClicked', (data) => console.log('visual clicked', data)],
              ['pageChanged', (event) => console.log(event)],
            ])
          }
          cssClassName={classes.root}
          getEmbeddedComponent={async (embeddedReport) => {
            (window as any).report = embeddedReport;
          }}
        />
      )}
    </div>
  );
};

export default PowerBiCommonComponent;
